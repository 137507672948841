import './App.css'
import MainDash from './components/MainDash/MainDash';
import RightSide from './components/RightSide/RightSide';
import Sidbar from './components/sidbar/Sidbar';
import Table from './components/Table/Table';

function App() {
  return (
    <div className="App">
        <div className="AppGlass">
        <Sidbar/>
        <MainDash/>
        <RightSide/>
        </div>
    </div>
  );
}

export default App;
