import{
    UilEstate,
    UilClipboard,
    UilClipboardNotes,
    Uil12Plus,
    UilChart,
    UilXAdd,
    UilMoneyWithdrawal,
    UilCommentAltInfo
} from "@iconscout/react-unicons";
import img1 from "../imgs/img1.png"
import img2 from "../imgs/img2.png"
import img4 from "../imgs/img-4.jpeg"
export const SidbarData = [
    {icon : UilEstate,
    heading : "Dachboard"
    },
    {icon : UilClipboard,
    heading : "orders"
    },
    {icon : UilClipboardNotes,
    heading : "Customrs"
    },
    {icon : Uil12Plus,
    heading : "Products"
    },
    {icon : UilChart,
    heading : "Analytices"
    },
    // {icon : UilXAdd,
    // heading : "Customrs"
    // },
    

];
export const CardData = [
    {
        title: 'Sales',
        color: {
            background: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
            boxShadow: "0px 10px 20px 0px #e0c6f5"
        },
        barValue: 70,
        value: "25,970",
        png: UilCommentAltInfo, // تأكد من أن الأيقونة مستوردة بشكل صحيح
        series: [
            {
                name: 'Sales',
                data: [31, 40, 28, 51, 42, 109, 100]
            }
        ]
    },
    {
        title: 'Revenue',
        color: {
            background: "linear-gradient(180deg, #ff919d 0%, #fc929d 100%)",
            boxShadow: "0px 10px 20px 0px #fdc0c7"
        },
        barValue: 80,
        value: "14,500",
        png: UilMoneyWithdrawal, // تأكد من أن الأيقونة مستوردة بشكل صحيح
        series: [
            {
                name: 'Revenue',
                data: [10, 100, 50, 70, 80, 30, 60]
            }
        ]
    },
    {
        title: 'Expenses',
        color: {
            background: "linear-gradient(180deg, #f8d49a 0%, #ffca71 100%)",
            boxShadow: "0px 10px 20px 0px #f9d59b"
        },
        barValue: 60,
        value: "8,600",
        png: UilCommentAltInfo, // تأكد من أن الأيقونة مستوردة بشكل صحيح
        series: [
            {
                name: 'Expenses',
                data: [30, 20, 50, 80, 60, 70, 40]
            }
        ]
    }
];
// recent Update Card data

export const updatedata = [
    {
        img:img1,
        name : "Abd alrhman",
        noti: "has received samsung gadget for charging batary",
        time : "30 minutes ago"
    },
        {
        img:img2,
        name : " odai abuo kulaib",
        noti: "has oreder samsung gadget for charging batary",
        time : "20 minutes ago"
    },
        {
        img:img4,
        name : "ali sria",
        noti: "has received aopo gadget for charging batary",
        time : "50 minutes ago"
    },
]