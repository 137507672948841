import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './table.css'
function createData(name, TracingId, data, status) {
  return { name, TracingId, data, status };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 'Approved'),
  createData('Ice cream sandwich', 237, 9.0, 'pending'),
  createData('Eclair', 262, 16.0, 'Approved'),
  createData('Cupcake', 305, 3.7, 'tropical'),
  
];
const makeStyle = (status)=>{
if(status === 'Approved'){
return{
    background: 'rgb(145 254 159 /47%)',
    color: 'green'
}
}else if(status === 'pending'){
return{
    background: '#ffadad8f',
    color: 'red'
}
}
else{
    return{
        background: '#59bfff',
    color: 'white'
    }
}
}
export default function BasicTable() {
  return (
    <div className="table">
    <h3>Resent Order</h3>
    <TableContainer
    style={{boxshadow:'0 13px 20px 0px #80808092'}}
     component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Product </TableCell>
            <TableCell align="left">TracingId</TableCell>
            <TableCell align="left">data</TableCell>
            <TableCell align="left">statue</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.TracingId}</TableCell>
              <TableCell align="left">{row.data}</TableCell>
              <TableCell align="left">
              <span className='status' style={makeStyle(row.status)}>{row.status}</span>
              </TableCell>
              <TableCell align="left" className='details'>details</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}
