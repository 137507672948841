import React from "react";
import { useState } from "react";
import { motion,AnimateSharedLayout } from "framer-motion";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./card.css";
import { UilTimes } from "@iconscout/react-unicons";
import Chart from "react-apexcharts";
const Card = (props) => {
  const [expanded, setexpanded] = useState(false);
  return (
    <AnimateSharedLayout>
      {expanded ? (
        <Expandedcard param={props} setexpanded={() => setexpanded(false)} />
      ) : (
        <CompactCard param={props} setexpanded={() => setexpanded(true)} />
      )}
    </AnimateSharedLayout>
  );
};
// compactCard
function CompactCard({ param, setexpanded }) {
  const Png = param.png;
  return (
    <motion.div
      className="compactCard"
      style={{
        background: param.color.background,
        boxShadow: param.color.boxShadow,
      }}
      onClick={setexpanded}
      layoutId="ExpandadbleCard"
    >
      <div className="radialbar">
        <CircularProgressbar
          value={param.barValue}
          text={`${param.barValue}%`}
        />
        <span>{param.title}</span>
      </div>
      <div className="detail">
        <Png />
        <span>${param.value}</span>
        <span>Last 24 hours</span>
      </div>
    </motion.div>
  );
}
// Expandedcard
function Expandedcard({ param, setexpanded }) {
  const data = {
    options: {
      type: "area",
      height: "auto",
    },
    dropShadow: {
      enabled: false,
      enabledOnseries: undefined,
      top: 0,
      left: 0,
      blur: 3,
      color: "#000",
      opacity: 0.35,
    },
    fill: {
      colors: ["#fff"],
      type: "gradient",
    },
    stroke: {
      curve: "smooth",
      colors: ["white"],
    },
    tooltip: {
      x: {
        format: "dd/mm/yy/HH:mm",
      },
    },
    grid: {
      show: true,
    },
    xaxis: {
      type: "datatime",
      categorise: [
        "2022-09-19t00:00.000z",
        "2022-09-19t01:30.000z",
        "2022-09-19t02:00.000z",
        "2022-09-19t03:00.000z",
        "2022-09-19t04:00.000z",
        "2022-09-19t05:00.000z",
      ],
    },
  };
  return (
    <motion.div
      className="Expandedcard"
      style={{
        background: param.color.background,
        boxShadow: param.color.boxShadow,
      }}
      layoutId="ExpandadbleCard"
    >
      <div
      style={{alignSelf:'flex-end',cursor:'pointer',margin:'1rem'}}>
        <UilTimes onClick={setexpanded}
         />
      </div>
      <span>{param.title}</span>
      <div className="chartContainer">
        <Chart series={param.series} type="area" options={data.options} />
      </div>
      <span>last 24 hours</span>
    </motion.div>
  );
}
export default Card;
