import React from "react";
import "./sidbar.css";
import logo from "../../imgs/logo.png";
import { SidbarData } from "../../Data/Data";
import {UilBars, UilSignOutAlt} from "@iconscout/react-unicons"
import { useState } from "react";
import {motion} from "framer-motion"
const Sidbar = () => {
    const[selected , setSelected] = useState(0);
    const[click , setclik]=useState(false);
    const sidbarVariants ={
      true:{
        left:'0'
      },
      false:{
        left:"-60%"
      }
    }
  return (
    <>
    <div className="Bars" style={click?{left : '54%'}:{left:'5%'}}
    onClick={()=>setclik(!click)}>
    <UilBars/>
    </div>
    <motion.div className="sidbar" 
    variants={sidbarVariants}
    animate={window.innerWidth<=768?`${click}`:
    ''
    }
    >
      {/* logo */}
      <div className="logo">
        <img src={logo} alt="" />
        <span>
          sh<span>o</span>ps
        </span>
      </div>
      <div className="menu">
      {SidbarData.map((Item,index)=>{
        return(
            <div className={selected===index ? 'menuItem active' :'menuItem'}
            key={index}
            onClick={()=>setSelected(index)}>
            <Item.icon/>
            <span>
            {Item.heading}
            </span>
            </div>
        )
      })}
      <div className="menuItem">
      <UilSignOutAlt/>
      </div>
      </div>
    </motion.div>
    </>
  );
};

export default Sidbar;
