import React from 'react'
import CustomerReview from '../CustomerReview/CustomerReview'
import Ubdate from '../Ubdate/Ubdate'
import './RihtSide.css'
const RightSide = () => {
  return (
    <div className="RightSide">
    <div>
    <h3>Update</h3>
    <Ubdate/>
    </div>
    <div><h3>Customer Review</h3>
    <CustomerReview/>
    </div>
    </div>
  )
}

export default RightSide
