import React from 'react'
import Chart from 'react-apexcharts'
import './CustomerReview.css'
const CustomerReview = () => {
     const data = {
        series: [
            {
                name: 'Review',
                data: [30, 20, 50, 80, 60, 70, 100]
            }
        ],
    options: {
      type: "area",
      height: "auto",
      dropShadow: {
      enabled: false,
      enabledOnseries: undefined,
      top: 0,
      left: 0,
      blur: 3,
      color: "#000",
      opacity: 0.35,
    },
    fill: {
      colors: ["#fff"],
      type: "gradient",
    },
    stroke: {
      curve: "smooth",
      colors: ["#ff929f"],
    },
    tooltip: {
      x: {
        format: "dd/mm/yy/HH:mm",
      },
    },
    grid: {
      show: false,
    },
    xaxis: {
      type: "datatime",
      categorise: [
        "2022-09-19t00:00.000z",
        "2022-09-19t01:30.000z",
        "2022-09-19t02:00.000z",
        "2022-09-19t03:00.000z",
        "2022-09-19t04:00.000z",
        "2022-09-19t05:00.000z",
      ],
    },
    },
    
  };
  return (
    <div className="CustomerReview">
    <Chart series={data.series} options={data.options} type={'area'}/>
    </div>
  )
}

export default CustomerReview
